<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        :content="content"></Horizontal>
        <Vertical ref="Vertical" v-else
        :content="content"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/LogoffIndex/Horizontal/Agreement.vue'
    import Vertical from '@/components/Mine/LogoffIndex/Vertical/Agreement.vue'
    
    import avater from '@/assets/menuList/icon_account.svg'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
             contentList:[
                 "1.账号处于安全状态，账号为正常使用未被封号、被盗等风险",
                 "2.游戏收益已结清或得到妥善处理，游戏内礼包、道具、现金提现等收益，请您妥善处理，若未处理，视为您自愿放弃收益",
                 "3.账号订单未完成状态，购买道具未完成支付订单无法注销",
                 "4.账号无任何纠纷：包括投诉举报、被投诉举报、仲裁、诉讼等纠纷",
                 "5.角色已离线"
             ],
			 content:null
            }
        },
        created() {
          this.getAgreement()  
        },
        methods:{
           getAgreement() {
               this.$api.user.getLogoffAgreement({}).then(res=> {
				   if (res.data.code === 200) {
				       console.log(res.data.data)
				   }
			   })
           }
        }
    }
</script>

<style>
</style>