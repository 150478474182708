<template>
    <div class="all-page">
        <TopNav title="用户注销协议" backPath="LogoffIndex"></TopNav>
        <div class="all-page-content-box">
          <!-- <div class="title">
              《账号注销协议条件》
          </div>
          <div class="content-item" v-for="(item, index) in contentList" :key="index">
              {{item}}
          </div>
          <div class="red-text">
              <div class="red-text-title">
                  特别提示：
              </div>
              <div class="red-text-content">
                  &nbsp;&nbsp;&nbsp;&nbsp;由于游戏中可在多个区服创建多个角色，申请注销后，将注销游戏内所有角色信息无法找回，且平台的所有游戏账户都将注销；请慎重！！！申请注销后账号将被冻结无法登录，平台将保留7天时间，7天之内还可通过客服找回账号，如7天内未找回账户平台将永久注销账号。
              </div>
          </div> -->
		  <div v-html="content"></div>
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"

    export default {
        components: {
            TopNav
        },
        props: {
            content:String
        },
        data() {
            return {
                
                
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped>
   /* .title {
       padding-bottom: 8px;
       font-size: 10px;
       font-weight: 500;
       color: #000000;
   } */
   /* .content-item {
       padding-bottom: 6px;
       font-size: 9px;
       font-weight: 400;
       line-height: 13px;
       color: #666666;
   }
   .red-text {
       padding-top: 2px;
   }
   .red-text-title {
       font-size: 9px;
       font-weight: 400;
       color: #FF2626;
   }
   .red-text-content {
       font-size: 9px;
       font-weight: 400;
       color: #FF5C5C;
   } */
</style>
